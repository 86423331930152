:root {
  --bg-color-blue: #2c3342;
  --accent-color-mint: #11c4a9;
  --accent-color-mint-loud: #09f7d4;
  --font-color-light: #fff;
  --font-color-dark: #050506;
  --font-size-xs: 1rem;
  --font-size-s: 1.2rem;
  --font-size-m: 2rem;
  --font-size-lg: 2.5rem;
  --font-size-xlg: 2.75rem;
  --font-size-xxlg: 3rem;
  --font-style-headings: monospace;
  --font-style-default: 'Quicksand', sans-serif;
}

body {
  margin: 0;
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-m);
  color: var(--font-color-light);
  background-color: var(--bg-color-blue);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

h1,
h2,
h3,
h4 {
  color: var(--font-color-light);
  font-family: var(--font-style-headings);
  margin: 20px 0;
}

h1 {
  font-size: var(--font-size-xxlg);
  font-weight: 500;
}

h2 {
  font-size: var(--font-size-xlg);
  font-weight: 400;
  border-bottom: 1px dotted #fff;
}

h3 {
  font-size: var(--font-size-lg);
  font-weight: 400;
}

h4 {
  font-size: var(--font-size-m);
  font-weight: 400;
}

p,
em,
aside,
a {
  font-size: var(--font-size-s);
  font-family: var(--font-style-default);
}

a {
  font-size: var(--font-size-s);
  color: var(--accent-color-mint-loud);
  text-decoration: none;
}

ul,
ol {
  font-family: var(--font-style-default);
  font-size: var(--font-size-s);
}

li a {
  color: var(--font-color-light);
}

main {
  margin: 40px;
}

section {
  padding-bottom: 40px;
}

li {
  margin: 20px 0;
}

aside {
  font-size: var(--font-size-s);
  padding: 20px;
  margin: 20px;
  max-width: 1024px;
  background: var(--font-color-dark);
  color: var(--font-color-light);
  border-radius: 5px;
  border-left: 3px solid var(--accent-color-mint);
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 1px 5px 0 rgb(0 0 0 / 20%);
}

img {
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 1px 5px 0 rgb(0 0 0 / 20%);
  padding: 5px;
}

nav a {
  font-family: var(--font-style-headings);
  color: var(--font-color-dark);
  font-size: var(--font-size-m);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

footer {
  display: flex;
  justify-content: space-between;
  background: var(--accent-color-mint);
  color: #000;
  font-family: var(--font-style-headings);
  font-size: var(--font-size-s);
  font-weight: 600;
}

button {
  font-family: var(--font-style-default);
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: var(--font-size-xlg);
    font-weight: 500;
  }

  h2 {
    font-size: var(--font-size-lg);
    font-weight: 400;
    /* border-bottom: 1px #fff dotted; */
  }

  h3 {
    font-size: var(--font-size-m);
  }

  p,
  em,
  a {
    font-size: var(--font-size-s);
  }
  main {
    margin: 10px 20px;
  }

  section {
    padding-bottom: 20px;
  }

  nav a:hover {
    text-decoration: none;
  }

  footer {
    flex-direction: column;
    align-items: center;
  }
}
