/*
* https://labs.loupbrun.ca/buttons/ 
* Classes need to be linked so everything below updated else you must wrap in a div putting the type in parent. 
* Chi is done with classes applied to btn 
*/
.chi.btn {
  display: inline-block;
  font-size: var(--font-size-xs);
  font-weight: 600;
  padding: 10px 20px;
  border: 3px solid #000;
  background-color: var(--accent-color-mint);
  border-radius: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: bottom;
  position: relative;
  transition: color 0.4s, transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-sizing: border-box;
  outline: none;
}
.chi.btn:hover:before {
  transform: scaleY(1);
}
.chi.btn:hover:after {
  transform: translate(6px, 6px);
}
.chi.btn:active {
  transition-duration: 0.05s;
  transform: translate(6px, 6px);
}
.chi.btn:active:after {
  transition-duration: 0.05s;
  transform: translate(0, 0);
}
.chi.btn:before,
.chi.btn:after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.chi.btn:before {
  transform-origin: top center;
  transform: scaleY(0);
  transition: transform cubic-bezier(1, 0, 0, 1) 0.1s;
}
.chi.btn:after {
  top: -3px;
  left: -3px;
  border: 3px solid var(--accent-color-mint);
  transition: transform cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s;
}
.chi.btn.btn-disabled {
  cursor: not-allowed;
  opacity: 0.8;
}
.chi.btn.btn-disabled:active {
  opacity: 0.6;
}
.chi.btn-primary {
  color: #52c11f;
  border-color: #52c11f;
}
.chi.btn-primary:hover {
  color: #e6eaef;
}
.chi.btn-primary:before {
  background-color: #52c11f;
}
.chi.btn-primary:after {
  border-color: #52c11f;
}
.chi.btn-secondary {
  color: #0b44ef;
  border-color: #0b44ef;
}
.chi.btn-secondary:hover {
  color: #e6eaef;
}
.chi.btn-secondary:before {
  background-color: #0b44ef;
}
.chi.btn-secondary:after {
  border-color: #0b44ef;
}
.chi.btn-tertiary {
  color: #ff006a;
  border-color: #ff006a;
}
.chi.btn-tertiary:hover {
  color: #e6eaef;
}
.chi.btn-tertiary:before {
  background-color: #ff006a;
}
.chi.btn-tertiary:after {
  border-color: #ff006a;
}
.chi.btn-tetrary {
  color: #991be2;
  border-color: #991be2;
}
.chi.btn-tetrary:hover {
  color: #e6eaef;
}
.chi.btn-tetrary:before {
  background-color: #991be2;
}
.chi.btn-tetrary:after {
  border-color: #991be2;
}
.chi.btn-black {
  color: #252730;
  border-color: #252730;
}
.chi.btn-black:hover {
  color: #e6eaef;
}
.chi.btn-black:before {
  background-color: #252730;
}
.chi.btn-black:after {
  border-color: #252730;
}
.chi.btn-dark {
  color: #3f4351;
  border-color: #3f4351;
}
.chi.btn-dark:hover {
  color: #e6eaef;
}
.chi.btn-dark:before {
  background-color: #3f4351;
}
.chi.btn-dark:after {
  border-color: #3f4351;
}
.chi.btn-gray {
  color: #6d6f7f;
  border-color: #6d6f7f;
}
.chi.btn-gray:hover {
  color: #e6eaef;
}
.chi.btn-gray:before {
  background-color: #6d6f7f;
}
.chi.btn-gray:after {
  border-color: #6d6f7f;
}
.chi.btn-light {
  color: #e6eaef;
  border-color: #e6eaef;
}
.chi.btn-light:hover {
  color: #5d7592;
}
.chi.btn-light:before {
  background-color: #e6eaef;
}
.chi.btn-light:after {
  border-color: #e6eaef;
}
